<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card>
          <b-row>
            <b-col sm="2">
              <b-form-group label="Region" label-for="select-wmo" size="sm">
                <b-form-select v-model="t_wmoid" class="" id="select-wmo" size="sm">
                  <option :value="option">-Pilih-</option>
                  <option value="a">Region I</option>
                  <option value="b">Region II</option>
                  <option value="c">Region III</option>
                  <option value="d">Region IV</option>
                  <option value="e">Region V</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Provinsi" label-for="select-station" size="sm">
                <b-form-select v-model="t_station" id="select-station" size="sm">
                  <option :value="option">-Pilih-</option>
                  <option value="a">Nanggroe Aceh Darussalam</option>
                  <option value="b">Sumatera Utara</option>
                  <option value="c">Sumatera Selatan</option>
                  <option value="d">Sumatera Barat</option>
                  <option value="e">Bengkulu</option>
                  <option value="f">Riau</option>
                  <option value="g">Kepulauan Riau</option>
                  <option value="h">Jambi</option>
                  <option value="i">Lampung</option>
                  <option value="j">Bangka Belitung</option>
                  <option value="k">Kalimantan Barat</option>
                  <option value="l">Kalimantan Timur</option>
                  <option value="m">Kalimantan Selatan</option>
                  <option value="n">Kalimantan Tengah</option>
                  <option value="o">Kalimantan Utara</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Kabupaten" label-for="select-station" size="sm">
                <b-form-select v-model="t_station" id="select-station" size="sm">
                  <option :value="option">Pilih</option>
                  <option value="1">Kabupaten 1</option>
                  <option value="2">Kabupaten 1</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group label="Station" label-for="select-station" size="sm" inline id="select-form">
                <b-form-select v-model="selectedForm" id="select-station" size="sm">
                  <option :value="option">-Pilih-</option>
                  <option value="a">AAWS</option>
                  <option value="b">AGM 1A</option>
                  <option value="c">AGM 1B</option>
                  <option value="d">ARG</option>
                  <option value="e">CDM</option>
                  <option value="f">Casella</option>
                  <option value="g">DT12</option>
                  <option value="h">FKLIM</option>
                  <option value="i">HL10</option>
                  <option value="j">Intensitas Hujan</option>
                  <option value="k">KU - KAH</option>
                  <option value="l">KU - N02</option>
                  <option value="m">KU - S02</option>
                  <option value="n">KU - SPM</option>
                  <option value="o">Klimat</option>
                  <option value="p">ME45</option>
                  <option value="q">ME48</option>
                  <option value="r">Magnet</option>
                  <option value="s">Metar</option>
                  <option value="t">PILOT</option>
                  <option value="u">Penguapan</option>
                  <option value="q">Petir - DB3</option>
                  <option value="q">Petir - KML</option>
                  <option value="q">Petir - LDC</option>
                  <option value="q">Petir - NEX</option>
                  <option value="q">Petir - TXT</option>
                  <option value="q">Pos Hujan</option>
                  <option value="q">RASON</option>
                  <option value="q">Radar - NetCDF (QPE)</option>
                  <option value="q">Radar - NetCDF (Reflectivity)</option>
                  <option value="q">Radar - Raw</option>
                  <option value="q">SMS Hujan</option>
                  <option value="q">Satellite - NetCDF</option>
                  <option value="q">Satellite - Raw</option>
                  <option value="q">Vaisala</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="3 pad2">
              <b-form-group label="WMO ID" label-for="select-station" size="sm">
                <b-form inline id="select-form">
                  <b-form-select class="col-7 mr-1 pad2" size="sm">
                    <option :value="option">Wmo Id</option>
                    <option value="01">00001</option>
                    <option value="02">00002</option>
                    <option value="03">00003</option>
                  </b-form-select>
                  <b-button v-on:click="onFetch" variant="primary" class="ml-sm-6" size="sm">Fetch</b-button>
                </b-form>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col sm="4">
              <!-- Parameter -->

              <b-card no-body class="border mt-1">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-1">
                    <feather-icon icon="UsersIcon" size="14" />
                    <span class="align-middle ml-50">Parameter</span>
                  </b-card-title>
                </b-card-header>
                <b-row class="pl-1 pr-1 mt-1">
                  <b-col>
                    <b-form-group label-cols="4" label-cols-lg="6" label-size="sm" label="Station ID :" label-for="input-sm">
                      <b-form-input id="input-sm" size="sm" placeholder="Auto" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col>
                    <b-form-group label-cols="4" label-cols-lg="6" label-size="sm" label="Station Name :" label-for="input-sm">
                      <b-form-input id="input-sm" size="sm" placeholder="Auto" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col>
                    <b-form-group label-cols="4" label-cols-lg="6" label-size="sm" label=" Element :" label-for="input-sm">
                      <b-form-select v-model="t_station" id="select-station" size="sm">
                        <option :value="option">Pilih</option>
                        <option value="1">Hari Hujan</option>
                        <option value="2">Suhu Udara</option>
                        <option value="3">Tekanan Udara</option>
                        <option value="4">Kelembapan Nisbi Udara</option>
                        <option value="5">Lama Penyinaran Matahari</option>
                        <option value="6">Arah dan Kecepatan Angin</option>
                        <option value="7">Penguapan</option>
                        <option value="7">Curah Hujan</option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col>
                    <b-form-group label-cols="4" label-cols-lg="6" label-size="sm" label=" Parameter :" label-for="input-sm">
                      <b-form-select v-model="t_station" id="select-station" size="sm">
                        <option :value="option">Pilih</option>
                        <option value="1">Parameter I</option>
                        <option value="2">Parameter II</option>
                        <option value="3">Parameter III</option>
                        <option value="4">Parameter IV</option>
                        <option value="5">Parameter V</option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="pl-1 pr-1">
                  <b-col>
                    <b-form-group label-cols="4" label-cols-lg="6" label-size="sm" label="Year From :" label-for="input-sm">
                      <b-form-input id="input-sm" size="sm" placeholder="Input" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col>
                    <b-form-group label-cols="4" label-cols-lg="6" label-size="sm" label="Year To :" label-for="input-sm">
                      <b-form-input id="input-sm" size="sm" placeholder="Input" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
              <!--  button car1 -->
              <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Add
              </b-button>
              <b-button variant="gradient-danger" class="mb-1 mb-sm-0 mr-0 mr-sm-1" type="reset" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Cancel
              </b-button>
            </b-col>

            <b-col sm="8">
              <!-- Parameter TABLE -->
              <b-card no-body class="border mt-1">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-1">
                    <feather-icon icon="UsersIcon" size="14" />
                    <span class="align-middle ml-50">Data Extraction</span>
                  </b-card-title>
                </b-card-header>
                <div class="table-responsive text-nowrap">
                  <b-table responsive hover class="mb-0" :items="items" />
                </div>
              </b-card>
              <!-- Action Buttons -->
              <b-button variant="success" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Download
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <aaws v-if="choosenForm == 'a'"> </aaws>
    <!-- <aktinograph v-if="choosenForm=='02'"> </aktinograph>
    <thermohigograph v-if="choosenForm=='03'"> </thermohigograph>
    <hujan v-if="choosenForm=='04'"> </hujan>
    <barograph v-if="choosenForm=='05'"> </barograph>
    <perawanan v-if="choosenForm=='06'"> </perawanan>
    <hujan-helman v-if="choosenForm=='07'"> </hujan-helman>
    <kelembaban-tanah v-if="choosenForm=='08'"> </kelembaban-tanah>
    <fenologi v-if="choosenForm=='09'"> </fenologi> -->
  </div>
</template>

<script>
//import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput, BTable } from "bootstrap-vue";

//import aaws from "./monitoringcomponent/aaws.vue";
// import Aktinograph from "./piascomponent/aktinograph.vue";
// import Barograph from "./piascomponent/barograph.vue";
// import Fenologi from "./piascomponent/fenologi.vue";
// import Hujan from "./piascomponent/hujan.vue";
// import HujanHelman from "./piascomponent/hujanhelman.vue";
// import KelembabanTanah from "./piascomponent/kelembapantanah.vue";
// import Perawanan from "./piascomponent/perawanan.vue";
// import Thermohigograph from "./piascomponent/thermohigoghraph.vue";

// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    BTable,
    //aaws,
    // Aktinograph,
    // Barograph,
    // Fenologi,
    // Hujan,
    // HujanHelman,
    // KelembabanTanah,
    // Perawanan,
    // Thermohigograph,
  },
  data: function() {
    return {
      items: [
        {
          Station_ID: "00040",
          Station_Name: "Station Klimatologi Malang",
          Element: "Value",
          Parameter: "Value",
          year_From: "2020",
          Year_To: "2021",
        },
      ],
    };
  },

  methods: {
    onFetch() {
      this.choosenForm = this.selectedForm;
    },
  },
  // computed: {
  //   nameState() {
  //     return this.name.length > 2;
  //   }
  // },
  //   mounted() {
  //     this.$store.dispatch(SET_BREADCRUMB, [
  //       { title: "Klimatologi", route: "piasmain" },
  //       { title: "PIAS" },
  //     ]);
  //   },
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
</style>
